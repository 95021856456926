
.form-dealer__popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 8888;
    // background: map-get($color,details-popup );
    background: rgba(#000, 0.8);

    display: flex;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: .3s ease-in-out;
    z-index: 10000;
    min-height: 100%;

    .contact-1__section--form{
        padding: 0;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) scale(0);
        transition: .4s ease-in-out;
        min-height: 100%;
        z-index: 10001;
    }
    .btn-close{
        position: absolute;
        top: 0px;
        right: 0px;
        // padding: 5px;
        width: 42px;
        height: 42px;
        border-radius: 0 0 0 16px;;
        // background: #041E42;
        cursor: pointer;
        background: #A32732;

        &::after{
            position: absolute;
            width: 100%;
            height: 100%;
            transform: scale(0);
            top: 0;
            left: 0;
            content: "";
            // background: map-get($color, button);
            background: white;
            border-radius: 0 0 0 16px;
            z-index: 2;
            transition: .3s ease-in-out;
            box-shadow: 0 3px 6px rgba(0,0,0,.16);
        }
        &:hover{
            .line-1,
            .line-2{
                // background: map-get($color, btn-hiden  );
                // background: #041E42;
                background: #A32732;
            }
            &::after{
                transform: scale(1);
            }
        }
        
        .line-1,
        .line-2{
            position: absolute;
            top: 50%;
            left: 50%;
            width: 3px;
            height: 20px;
            border-radius: 20px;
            // background: map-get($color, button );
            background:white;
            z-index: 3;
            
        }
        .line-1{
            transform: translate(-50%, -50%) rotate(45deg) ;
        }.line-2{
            transform: translate(-50%, -50%) rotate(135deg) ;
        }
    }
    .form-alert__popup--wrapper{
        // background: map-get($color, sub6 );
        background: #FFFFFF;
        max-width: 802px;

        width: 96%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) scale(0);
        transition: .4s ease-in-out;
        min-height: 100%;
        z-index: 10001;
        padding: 80px 45px;

        @media (max-width: 420px) {
            padding: 40px 24px;
        }

        display: flex;
        flex-direction: column;
        
        .popup-dealer{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            
            margin: 0 -2%;

            @media (max-width: 768px) {
                margin: 0;
            }

            position: relative;

            @media (max-width: 768px) {
                flex-direction: column;
            }

            &--or{
                position: absolute;
                top: calc(89px / 2);
                left: 50%;
                transform: translate(-50%, -50%);

                @media (max-width: 768px) {
                    top: 50%;
                }

                &>*{
                    font-weight: bold;
                    // color: #041E42;
                    color: #A32732;
                    font-size: 24px;
                }
            }

            &__item{
                padding: 0 2%;

                @media (max-width: 768px) {
                    padding: 0 0;

                    &:not(:first-child){
                        margin-top: 54px;
                    }
                }

                flex: 50%;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                img{
                    height: auto;
                    object-fit: contain;
                    width: auto;
                }

                .btn{
                    margin-top: 20px;

                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    white-space: nowrap;

                    @media (max-width: 768px) {
                        max-width: 420px;
                    }

                    
                    .btn-arrow{
                        border-radius: 4px;
                        border-width: 2px;
                        flex: 1;
                        padding: 12px 16px;

                        font-weight: bold;
                        font-family: inherit;

                        display: block;
                        text-align: center;

                        line-height: initial;
                    }
                }

            }


        }
    }
}
.form-dealer__popup.active{
    visibility: visible;
    opacity: 1;
    .form-alert__popup--wrapper{
        transform: translate(-50%,-50%) scale(1);
    }
    // .contact-1__section--form,
    // .form-alert__popup--wrapper{
    // opacity: 0.5;
    .contact-1__section--form{
        transform: translate(-50%,-50%) scale(1);
       
    }
}